.App header {
  padding: 1rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App header h1 {
  font-size: 1.25rem;
}

.tryIt {
  padding: 1rem 4rem;
  font-size: 1.25rem;
}

.propertyCard {
  border-radius: 0.5rem;
  overflow: hidden;
}
.upload-button {
  background: linear-gradient(90deg, #b731dd 0, #4161eb 100%);
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: 150ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  box-shadow: 5px 5px 10px rgba(17, 17, 17, 0.25);
  color: white;
}

.upload-button:hover {
  transform: scale(1.05);
}

.btn {
  background: linear-gradient(90deg, #b731dd 0, #4161eb 100%);
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: 150ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  box-shadow: 5px 5px 10px rgba(17, 17, 17, 0.25);
}

.buttonChange {
  border:  2px solid red;
}

.btn:hover {
  transform: scale(1.05);
}

.App header a {
  color: white;
  text-decoration: none;
}

.App main {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
}

.App .upload-wrapper {
  display: flex;
  justify-content: center;
}

.App main .image-grid {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
  row-gap: 6rem;
  list-style: none;
  margin-top: 6rem;
  text-align: center;
  padding-left: 0;
}

.image-grid p {
  grid-column-start: 3;
  opacity: 50%;
}

.image-grid li {
  overflow: hidden;
  height: 200px;
}

.image-grid img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
